'use client'

import { useEffect, useState } from "react";

interface CarouselProps {
  images: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 6000); // Change image every 10 seconds

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className="relative w-full h-full">
      <div className="overflow-hidden w-full h-full">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`carousel-${index}`}
            className={`w-full h-full object-contain ${index === currentImageIndex ? "block" : "hidden"}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
