import ThemeToggle from './themetoggle';
import './style.css';
import { GithubIcon, LinkedinIcon } from '../../assets/iconSvg';

function Navbar() {
  return (
    <div className=" flex justify-center bg-gradient-to-r from-colorLb via-colorLa to-colorLa dark:from-colorDa dark:via-colorDa dark:to-colorDb">
      <nav className="flex min-h-[50px] sm:direction-column ">
        <div className="container flex flex-wrap items-center justify-center">
          <button
            data-collapse-toggle="mobile-menu"
            type="button"
            className="md:hidden text-colorDa dark:text-colorLa rounded-lg inline-flex items-center justify-center"
            aria-controls="mobile-menu-2"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
            <svg
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div className="hidden md:block w-full md:w-auto" id="mobile-menu">
            <ul className="flex-col items-center md:flex-row flex mt-4 md:mt-0 md:text-sm md:font-medium">
              <li className="liNavbar px-2">
                <ThemeToggle />
              </li>


              <li className=" ">
                <a
                  href="/"
                  className=" min-w-[120px] mx-1 text-center liNavbar text-colorDa dark:text-colorLa hover:bg-colorLc dark:hover:bg-colorDc rounded-lg md:hover:bg-transparent md:border-0 block mt-2 py-2 md:p-0 "
                >
                  Home
                </a>
              </li>     
              <li className="liNavbar">
  <button
    id="dropdownNavbarLink"
    data-dropdown-toggle="dropdownNavbar"
    className="min-w-[120px] mx-1 text-center liNavbar text-colorDa dark:text-colorLa md:hover:bg-transparent md:border-0 pl-3 py-2 mt-2 md:p-0 font-medium flex items-center md:w-auto hover:bg-colorLc dark:hover:bg-colorDc rounded-lg"
  >
    Projects
    <svg
      className="w-4 h-4 ml-1"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  </button>
  <div
    id="dropdownNavbar"
    className="min-w-[150px] hidden bg-colorLb dark:bg-colorDb text-base z-10 list-none divide-y divide-gray-100 rounded shadow my-4 w-80 md:w-auto block"
    >
    <ul className="py-1 min-w-[150px]" aria-labelledby="dropdownLargeButton">
      <li className='dark:hover:bg-colorDc hover:bg-colorLc '>
        <a
          href="/projects/checkey"
          className="text-sm text-colorDa dark:text-colorLa block px-4 py-2"
        >
          Checkey
        </a>
      </li>
      <li className='dark:hover:bg-colorDc hover:bg-colorLc '>
        <a
          href="/projects/parque-invierno"
          className="text-sm text-colorDa dark:text-colorLa block px-4 py-2"
        >
          Parque Invierno
        </a>
      </li>
      <li className='dark:hover:bg-colorDc hover:bg-colorLc '>
        <a
          href="/projects/turistai"
          className="text-sm text-colorDa dark:text-colorLa block px-4 py-2"
        >
          Turist AI
        </a>
      </li>
      <li className='dark:hover:bg-colorDc hover:bg-colorLc'>
        <a
          href="/projects"
          className="text-sm text-colorDa dark:text-colorLa block px-4 py-2"
        >
          All
        </a>
      </li>
    </ul>
  </div>
</li>
<li className="liNavbar">
                <a
                  href="/contact"
                  className="liNavbar min-w-[120px] mx-1 text-center text-colorDa dark:text-colorLa hover:bg-colorLc mt-2 dark:hover:bg-colorDc rounded-lg md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:p-0 "
                >
                  Contact
                </a>
              </li>     
            </ul>
          </div>
        </div>
        <div className="hidden visible md:flex justify-around mt-2">
            <a href="https://www.linkedin.com/in/capone-leonardo/"><div><LinkedinIcon size={'8'} /></div></a>
            <a href="https://github.com/Esquiafo/"><div><GithubIcon size={'10'} /></div></a>
          </div>
      </nav>
    </div>
  );
}

export default Navbar;
