import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./views/index/Index";
import ProjectIndex from "./views/projects/Index";
import Checkey from "./views/projects/checkey/Checkey";
import NotFound from "./views/notfound/NotFound";
import CheckeyFaq from "./views/projects/checkey/Faq";
import Contact from "./views/contact";
import Privacy from "./views/privacy";
import ParqueDownload from "./views/projects/parque-invierno/Downloads";
import TuristDownload from "./views/projects/turistai/Downloads";
import ParqueInvierno from "./views/projects/parque-invierno/IndexParque";
import TuristAI from "./views/projects/turistai/TuristAI";

function App() {
  

  return (
    <BrowserRouter>
    
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/projects" element={<ProjectIndex />} />
        <Route path="/projects/checkey" element={<Checkey />} />
        <Route path="/projects/checkey/faq" element={<CheckeyFaq />} />|
        <Route path="/projects/parque-invierno/" element={<ParqueInvierno />} />
        <Route path="/projects/parque-invierno/downloads" element={<ParqueDownload />} />
        <Route path="/projects/turistai/" element={<TuristAI />} />
        <Route path="/projects/turistai/downloads" element={<TuristDownload />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;