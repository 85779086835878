import React, { useState } from "react";

interface PropsButton {
  color?: string;
  children?: React.ReactNode;
  title?: string; 
}

const ButtonIcon: React.FC<PropsButton> = ({ color, children, title }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const buttonClasses = `flex direction-row items-center justify-center group relative h-12 w-48 overflow-hidden rounded-2xl text-lg font-bold text-white `;

  return (
    <button
    style={{ backgroundColor: `${color}` }}
    className={buttonClasses}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}

    >
    {title}
    {children}
    
    </button>
  );
};

export default ButtonIcon;
