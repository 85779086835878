
import HeadLayout from "../../components/layout/RootLayout";

const pageChildren = () => {

  return (
    <div className="flex-1 mx-2 px-2 sm:px-0">
   
      <div className="mb-10 sm:mb-0 mt-10 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <div className="group bg-gray-900/30 py-20 px-4 flex flex-col space-y-2 items-center cursor-pointer rounded-md hover:bg-gray-900/40 hover:smooth-hover">
          <a className="bg-gray-900/70 text-white/50 group-hover:text-white group-hover:smooth-hover flex w-20 h-20 rounded-full items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          </a>
          <a className="text-white/50 group-hover:text-white group-hover:smooth-hover text-center" href="#">Upcoming...</a>
        </div>
        <a href="/projects/checkey">
        <div className="relative group bg-colorLc dark:bg-colorDc py-10 sm:py-20 px-4 flex flex-col space-y-2 items-center cursor-pointer rounded-md hover:bg-gray-900/80 hover:smooth-hover">
          <img className="w-20 h-20 object-cover object-center rounded-full" src="/checkey/icon-128.png" alt="art" />
          <h4 className="text-white text-2xl font-bold capitalize text-center">Checkey</h4>
        </div>
        </a>
        <a href="/projects/parque-invierno">
        <div className="relative group bg-colorLc dark:bg-colorDc py-10 sm:py-20 px-4 flex flex-col space-y-2 items-center cursor-pointer rounded-md hover:bg-gray-900/80 hover:smooth-hover">
          <img className="w-20 h-20 object-cover object-center rounded-full" src="/parque-invierno/parque.jpeg" alt="art" />
          <h4 className="text-white text-2xl font-bold capitalize text-center">Parque de Invierno</h4>
        </div>
        </a>
        <a href="/projects/turistai">
        <div className="relative group bg-colorLc dark:bg-colorDc py-10 sm:py-20 px-4 flex flex-col space-y-2 items-center cursor-pointer rounded-md hover:bg-gray-900/80 hover:smooth-hover">
          <img className="w-20 h-20 object-cover object-center rounded-full" src="/turistai/logo.png" alt="art" />
          <h4 className="text-white text-2xl font-bold capitalize text-center">Turist AI</h4>
        </div>
        </a>
        
      </div>
    </div>
  );
};

function Projects() {
  return (
    <HeadLayout title="Projects" description="Hola" keywords="keyword">
      {pageChildren()}
    </HeadLayout>
  );
}

export default Projects;
