export const isFirefox = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.includes('firefox');
  };
export const isEdge = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.includes('edg');
  };
export const isOperaGX = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.includes('opr');
  };