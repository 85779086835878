import List from "../../../components/container/list";
import HeadLayout from "../../../components/layout/RootLayout";

// Faq.tsx

function CheckeyFaq() {
  const listParams = {
    title: 'Frequently Asked Questions',
    items: [
      { title: '1. What is Checkey?', description: 'Checkey is an extension designed to help users check regional key prices in the Steam store. It provides a convenient way to compare prices and find the best deals.' },
      { title: '2. How do I download and install Checkey?', description: 'You can download Checkey by visiting our official website or using the provided download link. Follow the installation instructions to add the extension to your browser.' },
      { title: '3. Is Checkey available for all browsers?', description: 'Checkey is currently available for popular browsers such as Google Chrome and Microsoft Edge. Check our supported browsers list for the latest updates.' },
      { title: '4. Does Checkey collect any personal information?', description: 'Checkey may collect location information based on your IP address for providing location-based features. However, we prioritize user privacy and do not collect personally identifiable information.' },
      { title: '5. How often is the extension updated?', description: 'We strive to provide regular updates to improve functionality, fix bugs, and enhance user experience. Check our release notes for information about the latest updates.' },
      { title: '6. Can I suggest a feature or report a bug?', description: 'Absolutely! We welcome feedback from our users. Feel free to visit our support page to submit feature requests, report bugs, or get assistance from our team.' },
      { title: '7. Are there any fees associated with using Checkey?', description: 'Checkey is free to use, and there are no hidden fees. Enjoy checking regional key prices in the Steam store without any additional costs.' },
      { title: '8. How can I contact customer support?', description: 'If you have any questions, concerns, or need assistance, you can reach out to our customer support team by visiting our contact page. We are here to help!' },
    ],
  };

  return (
    <HeadLayout title="Home" description="Porfolio | Projects | Stuffs" keywords="Leonardo Capone">
     
   
        {<List title={listParams.title} items={listParams.items} />}
    
    </HeadLayout>
  );
}

export default CheckeyFaq;
