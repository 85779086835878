import React from 'react';
import HeadLayout from "../../components/layout/RootLayout";

const PageChildren = () => (
  <div className="relative w-full flex items-top justify-center min-h-screen bg-gradient-to-r from-colorLa to-colorLa dark:from-colorDa dark:to-colorDb sm:items-center">
    <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
      <div className="mt-8 overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="p-6 mr-2 sm:rounded-lg">
            <h1 className="text-4xl sm:text-5xl text-gray-800 dark:text-white font-extrabold tracking-tight">
              Privacy Policy
            </h1>
            <p className="text-lg text-gray-700 dark:text-gray-300 mt-4">
              Our app is designed specifically for children and does not collect any personal or sensitive user data. We are committed to protecting your child's privacy and ensuring a safe and enjoyable experience.
            </p>
            <h2 className="text-2xl sm:text-3xl text-gray-800 dark:text-white font-bold mt-8">
              Transparency and User Privacy
            </h2>
            <p className="text-lg text-gray-700 dark:text-gray-300 mt-4">
              While our app does not request access to any sensitive permissions or data, we still provide this privacy policy to be fully transparent about our practices. Our privacy policy is available on our app's store listing page and within the app itself.
            </p>
            <h2 className="text-2xl sm:text-3xl text-gray-800 dark:text-white font-bold mt-8">
              Compliance with Legal Requirements
            </h2>
            <p className="text-lg text-gray-700 dark:text-gray-300 mt-4">
              We understand the importance of privacy and comply with all legal requirements to protect your child's information. Even though our app does not access any personal data, we still submit a privacy policy to provide full transparency.
            </p>
            <h2 className="text-2xl sm:text-3xl text-gray-800 dark:text-white font-bold mt-8">
              Contact Us
            </h2>
            <p className="text-lg text-gray-700 dark:text-gray-300 mt-4">
              If you have any questions or concerns about our privacy policy or practices, please feel free to contact us at <span className="text-green-600 font-semibold">leocapone@hotmail.com</span>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Privacy = () => (
  <div>
    <HeadLayout title="Privacy Policy" description="Privacy Policy for Children's App" keywords="privacy policy, children, data protection">
      <PageChildren />
    </HeadLayout>
  </div>
);

export default Privacy;

