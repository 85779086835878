import React from 'react';
import HeadLayout from "../../components/layout/RootLayout";

const pageChildren = () => {

    return(
        <div className="flex justify-center container flex-col">


        {/* <!-- Error Container --> */}
        <div className="flex  justify-center flex-col items-center">
            <div className="text-colorDc dark:text-colorLc font-bold text-7xl">
                Error: 404
            </div>

            <div className="text-colorDa dark:text-colorLa font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
                This page does not exist
            </div>

            <div className="text-colorDb dark:text-colorLb font-medium text-sm md:text-xl lg:text-2xl mt-8">
                The page you are looking for could not be found.
            </div>
        </div>
    </div>
    );
};

function NotFound() {
    return (
        <div>
            <HeadLayout title="NotFound" description="This endpoint doesn't exist" keywords="keyword" >
            {pageChildren()}
            </HeadLayout>  
        </div>
    );
}

export default NotFound;
