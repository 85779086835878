import { FirefoxSVG, ChromeSVG, RightArrowSVG, EdgeSVG, OperaGXSVG } from "../../../assets/iconSvg";
import ButtonIcon from "../../../components/container/button";
import Carousel from "../../../components/container/carousel";
import HeadLayout from "../../../components/layout/RootLayout";
import { isEdge, isFirefox, isOperaGX } from "../../../utils/browerType";
import './ParqueInvierno.css'




const pageChildren = () => {
  const images = ["/parque-invierno/info.png","/parque-invierno/municipio.png"]
  

  return (
    <div className="flex flex-col md:flex-row justify-center items-center w-full pt-4 px-10">
      <div className="flex flex-col justify-start  items-start">
        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">Parque Invierno</h1>
        <p className="max-w-2xl font-light text-colorDa dark:text-colorLa lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Aplicacion realizada el Municipio de Hurlingham</p>
        
      

        <div className="max-w-2xl  mx-4 ">
          <hr className="border-colorDa dark:border-colorLa " />
          <p className="mb-2 font-medium text-colorDa dark:text-colorLa">Características destacadas:</p>
          <ul className="list-disc mx-4 px-4">
            <li className="block text-colorDa dark:text-colorLa my-2">• Desarrollado en React Native.</li>
            <li className="block text-colorDa dark:text-colorLa my-2">• Mas de +18.000 dispositivos compatibles con la aplicacion.</li>
          </ul>
        </div>
        <div className="max-w-2xl m-4">
        <a href="https://appetize.io/app/b_k2bhi7nqqmzvkz3pkk64sw5yre" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg primary-300  dark:focus:ring-primary-900 dark:focus:ring-opacity-50">
      <ButtonIcon color={"#2ddac2"} title="Live Demo"> 
      <svg className="animate-bounce delay-600" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m17 13l-5 5m0 0l-5-5m5 5V6"></path></svg>
          
       
        
      </ButtonIcon>

    </a>
    <a href="/projects/parque-invierno/downloads" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg primary-300  dark:focus:ring-primary-900 dark:focus:ring-opacity-50">
      <ButtonIcon color={"#71da2d"} title="APK"> 
      <svg className="animate-bounce delay-600" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m17 13l-5 5m0 0l-5-5m5 5V6"></path></svg>
          
       
        
      </ButtonIcon>

    </a>
</div>
      </div>
      <div className="flex justify-center items-center mx-2 ">
        {/* Dropdown div here */}
      
        {/* Carousel */}
        <div style={{width: 400, height: 1000}} className="container mx-auto mt-8 md:py-20">
          <Carousel images={images} />
        </div>
      </div>
    </div>
  );
};

function ParqueInvierno() {
  return (
    <HeadLayout title="Parque invierno" description="Hola" keywords="keyword">
      {pageChildren()}
    </HeadLayout>
  );
}

export default ParqueInvierno;

