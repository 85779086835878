interface ListProps {
  title: string;
  items: { title: string; description: string }[];
}

const List = ({ title, items }: ListProps) => {
  return (
    <>
        <div className='flex m-2 p-2 justify-center flex-col items-start'>
        <h1 className=" text-gray-900 dark:text-colorLa text-4xl font-bold mb-8 text-center">{title}</h1>

{items.map((item, index) => (
  <details className="text-gray-800 dark:text-colorLb  mb-8 max-w-[30rem]" key={index}>
    <summary className=" text-2xl font-semibold mb-4">{item.title}</summary>
    <p className='text-colorDa dark:text-colorLa'>{item.description}</p>
  </details>
))}
        </div>
    </>
  );
};

export default List;
