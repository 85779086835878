import exp from "constants";
import { CSSSVG, DockerSVG, GitSVG, GraphQLSVG, HTMLSVG, JavaSVG, JavascriptSVG, JestSVG, NestSVG, NodeJsSVG, NpmSVG, ReactSVG, RightArrowSVG, SQLSVG, SpringbootSVG, TypescriptSVG } from "../../assets/iconSvg";
import HeadLayout from "../../components/layout/RootLayout";
import './style.css'
interface Skill {
  icon: any;
  title: string;
  rating: string;
  bar: string
}

interface StudyEntity {
  nombre?: string;
  institucion?: string;
  fecha?: string;
  duracion?: string;
  logo?: string;
  certificado?: string;
  descripcion?: string;
}

interface JobExperience {
  job: string;
  company: string;
  dates: string;
  description?: string | null;
}

const SkillCategory = ({ skills, main }: { skills: Skill[], main: string }) => {
  return (
    <div className="bg-colorLb dark:bg-colorDx transition delay-150 duration-300 pulse">
       <div className="  justify-center font-semibold flex text-2xl  text-white dark:text-colorLb  bg-colorLc x dark:bg-colorDx ">
            <h2 className="text-2xl my-2 dark:text-colorLa text-colorDa">{main}</h2>
      </div>
      <section className=" p-6 rounded-lg shadow-lg dark:shadow-colorDa dark:bg-colorDy shadow-colorLc bg-colorLa">
        <div className="flex flex-wrap justify-center">
          {skills.map((skill: Skill, index: number) => (
            <div className="flex flex-col items-center m-2 transition duration-300 ease-in-out hover:opacity-[1] hover:scale-150 " key={index}>
            
                <div className="flex items-center min-w-[120px] p-2 ">
                  <span className="w-8 h-8 mr-4 flex items-center justify-center">
                    {skill.icon}
                  </span>
                  <div className="flex-1">
                    <div className="items-center min-w-[45px]">
                      <p className="font-medium w-full text-sm mr-auto dark:text-colorLb text-gray-800">
                        {skill.title}
                      </p>
                      <span className={`px-2 py-1  w-full rounded-lg bg-gray-200 text-${skill.bar}-500 text-xs`}>
                        {skill.rating} / 10
                      </span>
                    </div>
                  </div>
                </div>
               
             
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

const ExperienceCategory = ({ experiences, main }: { experiences: JobExperience[], main: string }) => {
  return (
    <div className="bg-colorLb dark:bg-colorDx pulse">
      <div className="justify-center font-semibold flex text-2xl text-white dark:text-colorLb bg-colorLc x dark:bg-colorDx">
        <h2 className="text-2xl my-2 dark:text-colorLa text-colorDa">{main}</h2>
      </div>
      <section className="p-6 rounded-lg shadow-lg dark:shadow-colorDa dark:bg-colorDy shadow-colorLc bg-colorLa">
        <div className="flex flex-wrap justify-center">
          {experiences.map((experience: JobExperience, index: number) => (
            <article
              className="lg:w-[400px] flex shadowjustify-evenly flex-col content-center md:w-[300px] w-full mx-auto rounded-lg dark:bg-[#384457] shadow-lg p-5 my-2 text-colorDa relative group overflow-hidden transition-shadow duration-300 hover:shadow-colorLb/40 hover:scale-105"
              key={index}
            >
              <div className="group-hover:after absolute inset-0 opacity-0 transition-opacity duration-500 z-0"></div>
              <div className="z-10">
                <h2 className="text-2xl md:text-3xl lg:text-3xl text-center mt-4 font-semibold dark:text-colorLb">{experience.job}</h2>
                <span className="my-2 mw-fit block mx-auto text-2xl text-center font-semibold bg-clip-text">
                  <span className="text-xl text-colorDy text-center px-5 dark:text-colorLc">{experience.company}</span>
                </span>
                <p className="dark:text-colorLa text-center mt-2 font-serif tracking-tight lg:text-lg">{experience.description}</p>
                <div className="flex items-center justify-center">
                  <p className="mt-2 text-center dark:text-colorLa font-bold">{experience.dates}</p>
                </div>
              </div>
           
              <div className="absolute inset-0 bg-gradient-to-br from-colorDc/70 to-white dark:from-white dark:to-white opacity-10 z-0"></div>
            </article>
          ))}
        </div>
      </section>
    </div>
  );
};



const EducationCategory = ({ studies, main }: { studies: StudyEntity[], main?: string }) => {
  return (
    <div className="bg-colorLb dark:bg-colorDx transition delay-150 duration-300 pulse">
      <div className="justify-center font-semibold flex text-2xl text-white dark:text-colorLb bg-colorLc x dark:bg-colorDx">
        <h2 className="text-2xl my-2 dark:text-colorLa text-colorDa">{main}</h2>
      </div>
      <section className="p-6 rounded-lg shadow-lg dark:shadow-colorDa dark:bg-colorDy shadow-colorLc bg-colorL">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {studies.map((study, index) => (
            <div key={index} className="relative group overflow-hidden p-8 rounded-xl bg-white border border-gray-200 dark:border-colorDb dark:bg-[#38465E] p-2">
              <div aria-hidden="true" className="inset-0 absolute aspect-video border rounded-full -translate-y-1/2 group-hover:-translate-y-1/4 duration-300 bg-gradient-to-b from-blue-500 to-white dark:from-white dark:to-white blur-2xl opacity-25 dark:opacity-5 dark:group-hover:opacity-10"></div>
              <div className="relative text-center">
                <div className="avatar-holder flex items-center">
                  <div className="flex-shrink-0 min-w-16 w-16">
                    <img className="w-full rounded" src={study.logo} alt="Default avatar" />
                  </div>
                  <div className="flex flex-col text-center flex-grow min-w-[150px] p-2">
                    <h2 className="text-2xl font-semi dark:text-colorLa">{study.institucion}</h2>
                    <h2 className="text-md dark:text-colorLb pt-2">{study.nombre}</h2>
                  </div>
                </div>
                <div className="mt-6 pb-6">
                  <p className="text-gray-700 dark:text-gray-300 min-h-[150px]">{study.descripcion}</p>
                </div>
                <div className="flex gap-3 -mb-8 py-4 border-t border-gray-200 dark:border-gray-800 ">
                  {study.certificado ? (
                    <a href={`certificates/${study.certificado}`} target="_blank" className="hover:scale-110 group rounded-xl select-none disabled:border disabled:opacity-20 disabled:text-gray-950 disabled:border-gray-200 disabled:bg-gray-100 dark:disabled:border-gray-800 dark:bg-colorDy dark:text-white dark:hover:bg-gray-500/15 dark:active:bg-gray-500/10 flex items-center text-sm h-8 px-3.5 justify-center bg-gray-100 hover:bg-gray-200/75 active:bg-gray-100" rel="noreferrer">
                      <span>Certificate</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m17 13l-5 5m0 0l-5-5m5 5V6"></path></svg>
                    </a>
                  ) : (
                    <a className=" group rounded-xl select-none disabled:border disabled:opacity-20 disabled:text-gray-950/50 disabled:border-gray-200/50 disabled:bg-gray-100/50 dark:disabled:border-gray-800 dark:bg-gray-300 dark:text-gray-400 dark:hover:bg-gray-300 dark:active:bg-gray-300/75 flex items-center text-sm h-8 px-3.5 justify-center bg-gray-200" rel="noreferrer">
                    <span>Certificate X</span>
                         </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};


const SkillsChildren = () => {
  const skills: Skill[] = [
    {
      icon: JavascriptSVG({size: '32'}),
      title: "Javascript",
      rating: "9.3",
      bar: "green",
    },
    {
      icon: NodeJsSVG({size: '32'}),
      title: "Node.js",
      rating: "8.4",
      bar: "green",
    },
    {
      icon: TypescriptSVG({size: '42'}),
      title: "Typescript",
      rating: "8.3",
      bar: "green",
    },
    {
      icon: DockerSVG({size: '32'}),
      title: "Docker",
      rating: "7",
      bar: "blue",
    },
    {
      icon: GraphQLSVG({size: '32'}),
      title: "GraphQL",
      rating: "8.3",
      bar: "green",
    },
    {
      icon: JestSVG({size: '32'}),
      title: "Jest",
      rating: "7.4",
      bar: "blue",
    },
    {
      icon: NestSVG({size: '32'}),
      title: "Nest",
      rating: "8.7",
      bar: "green",
    },
    
    {
      icon: NpmSVG({size: '32'}),
      title: "NPM",
      rating: "7.3",
      bar: "blue",
    },
    {
      icon: GitSVG({size: '32'}),
      title: "Git",
      rating: "9",
      bar: "green",
    },
    {
      icon: SQLSVG({size: '32', color: '#1C274C'}),
      title: "SQL",
      rating: "7.4",
      bar: "blue",
    },
    {
      icon: SQLSVG({size: '32', color: 'orange'}),
      title: "NoSQL",
      rating: "6.7",
      bar: "orange",
    },
    {
      icon: HTMLSVG({size: '32'}),
      title: "HTML",
      rating: "8.5",
      bar: "green",
    },
    {
      icon: CSSSVG({size: '32'}),
      title: "CSS",
      rating: "7.2",
      bar: "blue",
    },
    {
      icon: ReactSVG({size: '32'}),
      title: "React",
      rating: "7.1",
      bar: "blue",
    },
    {
      icon: ReactSVG({size: '32', color:'#800080'}),
      title: "React Native",
      rating: "5.4",
      bar: "orange",
    },
    {
      icon: JavaSVG({size: '32'}),
      title: "Java",
      rating: "6.4",
      bar: "orange",
    },
    {
      icon: SpringbootSVG({size: '32'}),
      title: "Springboot",
      rating: "3.9",
      bar: "red",
    },

    
  ];

  return (


    <SkillCategory skills={skills} main="<Skills />"/>


  )
};

const ExperienceChildren = () => {
  const experiences: JobExperience[] =[
    {
      job: "Jr. Backend Developer",
      company: "Nimblelab",
      dates: "Aug 2023 - Feb 2024",
      description: "Docker · Jest · Nest · Sequelize · Next · DOM · CI/CD · AWS · OpenAI · Library Building · CRON"
    },
    {
      job: "Jr. Developer",
      company: "Qonico",
      dates: "Apr 2023 - Jul 2023",
      description: "Astro · React · React Native · Redis · Docker · Sequelize · Typescript · Javascript"
    },
    {
      job: "Teacher assistant",
      company: "Coderhouse",
      dates: "Mar 2023 - Dec 2023",
      description: "Next · Redux · Node · Rest · Back-End Web Development · PWA · Bootstrap ·  Nest · E-Commerce · Git · WebSockets ·  JWT · CRUD · jQuery · JavaScript · React"
    },
    {
      job: "Teacher",
      company: "Argentina School",
      dates: "Sep 2022 - Feb 2023",
      description: "Blockly Programming · HTML · CSS · JavaScript · SQL · MySQL · Arduino · Raspberry"
    },
    {
      job: "Web Developer",
      company: "Freelance",
      dates: "Jan 2021 - Feb 2023",
      description: 'HTML · CSS · Javascript · SQL · Serverless · Git · Vercel'
    },
    {
      job: "Technical Assistance",
      company: "CAT Technologies Argentina",
      dates: "Jun 2022 - Oct 2022",
      description: "IP · Online Support · DNS · Call Center · WiFi · Invoice · Email"
    },
    {
      job: "Help Desk",
      company: "Family Company",
      dates: "Mar 2014 - Jan 2020",
      description: 'Word · Excel · Powerpoint · Remote Support · Catching customers'
    },
    {
      job: "Assistant Manager",
      company: "GrupoGBK",
      dates: "Apr 2016 - Oct 2018",
      description: 'Photoshop · Illustrator · Excel · Word · Paint · Blender · Thinkercad'
    }
  ]
  

  return (
   

    <ExperienceCategory experiences={experiences} main="<Experience />"/>

  
  )
};

const EducationChildren = () => {
 
  const studies: StudyEntity[] = [
    {
        nombre: 'Software Engineer',
        institucion: 'Universidad de Buenos Aires',
        fecha: '2018 / Present',
        duracion: '6 months',
        logo: 'logos/uba.png',
        certificado: undefined,
        descripcion: 'University Degree '
    },
    {
      nombre: 'English Intermediate',
      institucion: 'Coderhouse',
      fecha: '2022',
      duracion: '6 months',
      logo: 'logos/coder.jpg',
      certificado: "coder-english.png",
      descripcion: 'Intermediate English level'
  },
  {
      nombre: 'RU102JS: Redis for JavaScript Developers',
      institucion: 'Redis',
      fecha: '2022',
      duracion: '6 months',
      logo: 'logos/redis.jpeg',
      certificado: "redis.png",
      descripcion: 'API, Promises, Cluster, Libraries, RESP, Javascript, Binary Data'
  },
  {
    nombre: 'Google Data Analytics',
    institucion: 'Google / Coursera',
    fecha: '2022',
    duracion: '6 months',
    logo: 'logos/google.jpeg',
    certificado: "data-analytics.jpeg",
    descripcion: 'Data Analysis, Creating case studies, Data Visualization, Data Integrity, Data Cleansing, Data Collection, Spreadsheet, SQL, Data Ethics, Aggregation,R Markdown, R Programming, Rstudio, Tableau Software'
},
{
    nombre: 'Testing QA Manual',
    institucion: 'Buenos Aires City Government',
    fecha: '2022',
    duracion: '6 months',
    logo: 'logos/gcba.jpeg',
    certificado: "qa-manual-cba.pdf",
    descripcion: 'Unit Test, E2E, Selenium, Jest, Good Practices, Manual Test'
},
{
  nombre: 'Programmer',
  institucion: 'Buenos Aires City Government',
  fecha: '2022',
  duracion: '6 months',
  logo: 'logos/gcba.jpeg',
  certificado: "programmer-cba.pdf",
  descripcion: 'Classes, Asynchronism, Interface, Java, Javascript, Python, Sql, OOP'
},
{
      nombre: 'Nest.js',
      institucion: 'Udemy',
      fecha: '2022',
      duracion: '6 months',
      logo: 'logos/udemy.jpeg',
      certificado: "nest.jpg",
      descripcion: 'Nest.js, Validation, Services, ORMs, CRUD, JWT, Authentication, Unit Test, Integration Test, Typescript'
},
{
    nombre: 'Checkout Pro SDK (Node)',
    institucion: 'Mercado Libre',
    fecha: '2022',
    duracion: '6 months',
    logo: 'logos/mercadolibre.jpeg',
    certificado: "checkout-mercadopago.pdf",
    descripcion: 'HTML, Javascript, Node.js, Server Configuration, API REST, Git, SDK Integration'
},
{
        nombre: 'React.js',
        institucion: 'Coderhouse',
        fecha: '2022',
        duracion: '6 months',
        logo: 'logos/coder.jpg',
        certificado: "coder-react.png",
        descripcion: 'ECMA6, DOM, JSX, Transpiling, Components, Promises, Async, MAP, API, Routing, Events, Context, Rendering, Firebase'
},
{
        nombre: 'Java Fullstack Springboot',
        institucion: 'Egg Live',
        fecha: '2021 / 2022',
        duracion: '6 months',
        logo: 'logos/egg.jpeg',
        certificado: 'java-egg.pdf',
        descripcion: 'Java, Collections, Classes, Encapsulation, Polymorphism, Overloading, Methods, JDBC, JPA, Spring, Maven'
},
{
        nombre: 'Fullstack Node.js',
        institucion: 'Digital House',
        fecha: '2019 / 2020',
        duracion: '6 mounths',
        logo: 'logos/digitalhouse.jpeg',
        certificado: 'digitalhouse.pdf',
        descripcion: 'Node.js, Express, React, MongoDB, MySQL, Javascript ES6-8'
},
{
        nombre: 'Game Developer Unity Basics ',
        institucion: 'UTN Elearning',
        fecha: '2018',
        duracion: '6 months',
        logo: 'logos/utn.jpeg',
        certificado: 'games-utn.png',
        descripcion: 'C++ Basics, Unity Software Basics, Blender Software Basics'
},

    
]

  return (
   

   <div>
    <EducationCategory studies={studies} main="<Education />"/>
    </div>

  
  )
};


const pageChildren = () => {
  return (
    <div className="flex justify-center flex-col min-h-full md:flex-row justify-center items-center w-full">
      <div className="flex flex-col items-center justify-center h-full justify-around">
      <div className="flex items-center w-full h-[900px] bg-light-yellow">
        <div className="flex flex-col items-center dark:text-colorLa w-full justify-center">
        <div className="flex flex-col items-center p-5 font-normal text-4xl">
          <h1 className="text-7xl sm:text-md my-3 ">  <span className="relative text-center inline-block before:absolute before:-inset-1 block before:-skew-y-3 text-colorLa text-colorLa before:bg-colorLc dark:before:bg-colorDa ">
        <span className="relative text-7xl skew-y-3 text-colorDa dark:text-colorLb">Hi! I'm Leonardo Capone.</span>
    </span></h1>
          <h2 className=" text-center mt-4 p-2 bg-[#f84525] text-white px-2 rounded-md">Backend Developer</h2>
          </div>
          <div className="flex flex-col  font-normal text-lg">
            <p className="px-4 mb-4 text-xl">+ 2 years of experience working with innovative products and creating high-end solutions</p>
            
            </div>
             <svg className="animate-bounce delay-600" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m17 13l-5 5m0 0l-5-5m5 5V6"></path></svg>
             <a href={`certificates/cv.pdf`} target="_blank" className="flex flex-col mt-4  group rounded-xl select-none disabled:border disabled:opacity-20 disabled:text-gray-950 disabled:border-gray-200 disabled:bg-gray-100 dark:disabled:border-gray-800   flex items-center text-sm   justify-center " rel="noreferrer">
          
            <h2 style={{display: 'inline-table'}} className=" h-[50px] w-[150px] hover:scale-110 text-lg  text-center p-2 bg-[#0071c2] text-white px-2  rounded-md">Know me better</h2>
            </a>
            </div>
          </div>
          
       

          <div className="flex items-center justify-center bg-gray-100 sm:mx-5 md:mx-10 my-4 lg:mx-30">
     
     <div >{EducationChildren()}</div>


   </div>
        <div className="flex items-center justify-center bg-gray-100 sm:mx-5 md:mx-10 my-4 lg:mx-30">
     
        <div>{ExperienceChildren()}</div>


   </div>

   <div className="flex items-center justify-center bg-gray-100 sm:mx-5 md:mx-10 my-4 lg:mx-30">
     
     <div className="">{SkillsChildren()}</div>


   </div>
      </div> 
    
    </div>
  );
};

function Index() {
  return (
    <HeadLayout title="Home" description="Porfolio | Projects | Stuffs" keywords="Leonardo Capone">
      
      {pageChildren()}
      
    </HeadLayout>
  );
}

export default Index;
