import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';

const Downloads = () => {
  const [isDownloaded, setIsDownloaded] = useState(false);
  const url = "https://capone.dev/projects/parque-invierno/downloads";
  useEffect(() => {
    if (!isDownloaded) {
      const link = document.createElement('a');
      link.href = `${process.env.PUBLIC_URL}/apks/turismo.apk`; // Specify the path to your file in the /public/ directory
      link.download = 'turismo.apk'; // Specify the name for the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownloaded(true);
    }
  }, [isDownloaded]);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px', display: 'flex', justifyContent: 'center'}}>
      <QRCode value={url} size={256} />
    </div>
  );

};

export default Downloads;
