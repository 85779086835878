import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../container/navbar';
import './style.css';

interface HeadLayoutProps {
  title: string;
  description: string;
  keywords: string;
  children?: React.ReactNode;
}

const HeadLayout: React.FC<HeadLayoutProps> = ({ title, description, keywords, children }) => {
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    // Function to check if the HTML element has the class "dark"
    const checkDarkClass = () => {
      const htmlElement = document.documentElement;
      const hasDarkClass = htmlElement.classList.contains('dark');
      setIsDark(hasDarkClass);
    };
// Run the initial check
checkDarkClass();

// Create a MutationObserver to watch for changes to the class attribute of the HTML element
const observer = new MutationObserver(checkDarkClass);
observer.observe(document.documentElement, { attributes: true });

// Cleanup function to disconnect the observer when the component unmounts
return () => {
  observer.disconnect();
};
}, []);

  const reverse = isDark ? '#7897a8' : '#b5c4d4';
  const inverse = isDark ? '#0F172A' : '#f0f5ff';


  return (
    <HelmetProvider>
      <Helmet>
        <script src="https://unpkg.com/@themesberg/flowbite@1.1.1/dist/flowbite.bundle.js"></script>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="google-adsense-account" content="ca-pub-2623922203603929"></meta>
			  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2623922203603929"
        crossOrigin="anonymous"></script>

		    <script async src="https://www.googletagmanager.com/gtag/js?id=G-MK1EHN59WY"></script>
		    <script src="/js/gtag.js"></script>

		    <meta name='impact-site-verification' defaultValue='d8d3b68a-6498-4c97-a5fe-03f5d34303e4'></meta>
		    <meta name='impact-site-verification' defaultValue='2236fe41-bc79-4f97-bdda-e15cd2abfc5b'></meta>
      </Helmet>
      <Navbar />

      <div
        className="main-content flex  items-start content-wrapper bg-gradient-to-r from-colorLa to-colorLa dark:from-colorDa min-h-full dark:to-colorDb"
        style={{
          '--inverse': `${inverse}`,
          '--reverse': `${reverse}`,
                                      
          backgroundImage: 'linear-gradient(180deg, var(--inverse) 0%, var(--reverse) 100%)',
        } as React.CSSProperties}
      >
       
          <div className='w-full flex justify-center min-h-screen'>
          {children}
          </div>
    
      </div>
    </HelmetProvider>
  );
};

export default HeadLayout;
