import { FirefoxSVG, ChromeSVG, RightArrowSVG, EdgeSVG, OperaGXSVG } from "../../../assets/iconSvg";
import ButtonIcon from "../../../components/container/button";
import Carousel from "../../../components/container/carousel";
import HeadLayout from "../../../components/layout/RootLayout";
import { isEdge, isFirefox, isOperaGX } from "../../../utils/browerType";
import './checkey.css'




const pageChildren = () => {
  const images = ["/checkey/checkey_eg.png"]
  
  const chromeLink = (
    <a href="https://chromewebstore.google.com/detail/checkey/gfpbhgnhliielafioekjempmbjolheof?hl=es" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg primary-300  dark:focus:ring-primary-900 dark:focus:ring-opacity-50">
      <ButtonIcon color={"#3498DBFF"} title="Descargar"> 
        <RightArrowSVG />
       
        { <ChromeSVG />}
      </ButtonIcon>

    </a>
  );

  const firefoxLink = (
    <a href="https://addons.mozilla.org/es/firefox/addon/checkey/" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg primary-300  dark:focus:ring-primary-900 dark:focus:ring-opacity-50">
      <ButtonIcon color={"#3498DBFF"} title="Descargar"> 
        <RightArrowSVG />
        {<FirefoxSVG />}   

</ButtonIcon>
    </a>
  );
  const edgeLink = (
    <a href="https://chromewebstore.google.com/detail/checkey/gfpbhgnhliielafioekjempmbjolheof?hl=es" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg primary-300  dark:focus:ring-primary-900 dark:focus:ring-opacity-50">
      <ButtonIcon color={"#3498DBFF"} title="Descargar"> 
        <RightArrowSVG />
        {<EdgeSVG />}   

</ButtonIcon>
    </a>
  );
  const operaLink = (
    <a href="https://chromewebstore.google.com/detail/checkey/gfpbhgnhliielafioekjempmbjolheof?hl=es" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg primary-300  dark:focus:ring-primary-900 dark:focus:ring-opacity-50">
      <ButtonIcon color={"#3498DBFF"} title="Descargar"> 
        <RightArrowSVG />
        {<OperaGXSVG />}   
      </ButtonIcon>
    </a>
  );

  return (
    <div className="flex flex-col md:flex-row justify-center items-center w-full pt-4 px-10">
      <div className="flex flex-col justify-start  items-start">
        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">Checkey</h1>
        <p className="max-w-2xl font-light text-colorDa dark:text-colorLa lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Todas los precios en un solo lugar.</p>
        
        {isFirefox() && firefoxLink}
        {isEdge() && edgeLink}
        {isOperaGX() && operaLink}
        {!isOperaGX() && !isEdge() && !isFirefox() && chromeLink}

        <div className="max-w-2xl  mx-4 ">
          <hr className="border-colorDa dark:border-colorLa " />
          <p className="mb-2 font-medium text-colorDa dark:text-colorLa">Características destacadas:</p>
          <ul className="list-disc mx-4 px-4">
            <li className="block text-colorDa dark:text-colorLa my-2">• Búsqueda y comparación de precios de keys en diversas tiendas en línea.</li>
            <li className="block text-colorDa dark:text-colorLa my-2">• Integración con Steam para verificar la disponibilidad de keys activables en su plataforma.</li>
            <li className="block text-colorDa dark:text-colorLa my-2">• Visualización del precio actualizado en tiempo real.</li>
            <li className="block text-colorDa dark:text-colorLa my-2">• Integración de Trustpilot para leer comentarios de otros usuarios sobre las tiendas.</li>
            <li className="block text-colorDa dark:text-colorLa my-2">• Distinguir resellers oficiales y no oficiales mediante colores.</li>
            <li className="block text-colorDa dark:text-colorLa my-2">• Redirección a SteamDB para ver el precio histórico junto al precio actual en Steam.</li>
            <li className="block text-colorDa dark:text-colorLa my-2">• Botón de donación para apoyar el desarrollo de la plataforma.</li>
          </ul>
        </div>
        <div className="max-w-2xl m-4 ">
          <p className="mx-4  font-medium text-colorDb dark:text-colorLa">Nota importante:</p>
          <p className="mx-4  text-sm text-colorDc dark:text-colorLb">Esta extensión proporciona precios de las tiendas y no garantiza la autenticidad de los productos. La ausencia de un juego en la lista no implica necesariamente su falta de disponibilidad. Se recomienda verificar la autenticidad y/o reputación del vendedor antes de realizar la compra.</p>
        </div>
      </div>
      <div className="flex justify-center items-center mx-2 ">
        {/* Dropdown div here */}
      
        {/* Carousel */}
        <div className="container mx-auto mt-8 md:py-20">
          <Carousel images={images} />
        </div>
      </div>
    </div>
  );
};

function Checkey() {
  return (
    <HeadLayout title="Checkey" description="Hola" keywords="keyword">
      {pageChildren()}
    </HeadLayout>
  );
}

export default Checkey;
